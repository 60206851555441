import axios from "axios";
import MockAdapter from "axios-mock-adapter";

export default {
  bootstrap() {
    var mock = new MockAdapter(axios);

    mock.onPost("/api/recallinfo").reply(function (config) {
      var vin = JSON.parse(config.data).vin;
      if (vin === "VALIDATIONERROR") {
        // VALIDATIONERRORは小文字でも可
        return [
          200,
          [
            {
              resultMessage: "validationError",
            },
          ],
        ];
      } else if (vin.length > 5) {
        return [
          200,
          [
            {
              vin: vin,
              status: "1",
              recallClass: "いいいいいいいい",
              title: "ああああああああああああああ",
              reportingDay: "2021/09/14",
              detailsPageUrl: "https://www.yahoo.co.jp/",
              resultMessage: "",
            },
            {
              vin: vin,
              status: "0",
              recallClass: "ううううううううううううううううう",
              title: "ええええええええええええええええええええええええええ",
              reportingDay: "2021/09/15",
              detailsPageUrl: "https://www.yahoo.co.jp/",
              resultMessage: "",
            },
            {
              vin: vin,
              status: "0",
              recallClass:
                "おおおおおおおおおおおおおおおおおおおおおおおおおおおおおお",
              title:
                "かかかかかかかかかかかかかかかかかかかかかかかかかかかかかか",
              reportingDay: "2021/09/15",
              detailsPageUrl: "https://www.yahoo.co.jp/",
              resultMessage: "",
            },
          ],
        ];
      } else {
        return [200, []];
      }
    });
  },
};
