<template>
  <div>
    <ModalPicture
      :modalFlg="modalFlg"
      :modalImgSrc="modalItems.modalImgSrc"
      @modal-clicked="modalFlg = $event"
    />
    <ModalContents
      :modalFlg="modalFlg"
      @modal-clicked="modalFlg = $event"
      :modalContentsImgSrc="modalItems.modalContentsImgSrc"
    />
  </div>
</template>

<script>
import ModalContents from "../components/ModalContents";
import ModalPicture from "../components/ModalPicture";

export default {
  name: "Modal",
  components: {
    ModalContents,
    ModalPicture,
  },
  props: ["modalItems"],
  data: function () {
    return {
      modalFlg: false,
    };
  },
};
</script>
