<template>
  <div>
    <v-img
      :src="modalImgSrc"
      @click="modalClick"
      style="cursor: hand; cursor: pointer"
    ></v-img>
    <p class="grey--text text--lighten-1">※画像をクリックすると拡大します</p>
  </div>
</template>

<script>
export default {
  name: "ModalPicture",
  props: ["modalFlg", "modalImgSrc"],
  data: function () {
    return {
      childModalFlg: this.modalFlg,
    };
  },
  methods: {
    modalClick() {
      this.childModalFlg = !this.modalFlg;
      this.$emit("modal-clicked", this.childModalFlg);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  background: #10afd5;
  color: #fff;
  font-size: 15px;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
}
</style>
