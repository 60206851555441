<template>
  <v-app>
    <v-app-bar>
      <HeaderText></HeaderText>
    </v-app-bar>
    <v-main class="text-body-2">
      <SummaryDescriptionText></SummaryDescriptionText>
      <RecallSearch></RecallSearch>
      <a name="VehicleInspection"></a>
      <template>
        <v-container>
          <v-divider class="mt-8"></v-divider>
        </v-container>
      </template>
      <VehicleInspectionText></VehicleInspectionText>
      <template>
        <v-container>
          <v-divider class="mt-8"></v-divider>
        </v-container>
      </template>
      <InquiryDescriptionText></InquiryDescriptionText>
      <template>
        <v-container>
          <div style="text-align: right">
            <a href="#top"><strong>このページの上へ</strong></a>
          </div>
        </v-container>
      </template>
    </v-main>
    <v-footer>
      <FooterText></FooterText>
    </v-footer>
  </v-app>
</template>

<script>
import HeaderText from "./views/HeaderText";
import SummaryDescriptionText from "./views/SummaryDescriptionText";
import VehicleInspectionText from "./views/VehicleInspectionText";
import InquiryDescriptionText from "./views/InquiryDescriptionText";
import FooterText from "./views/FooterText";
import RecallSearch from "./components/RecallSearch";

export default {
  name: "App",
  components: {
    HeaderText,
    SummaryDescriptionText,
    VehicleInspectionText,
    InquiryDescriptionText,
    FooterText,
    RecallSearch,
  },
};
</script>
