<template>
  <v-container>
    <v-row class="px-2">
      ■<strong>自動車検査証（車検証）車台番号 記載位置サンプル</strong>
    </v-row>
    <v-row class="px-2">
      <div class="grey--text text--lighten-1">
        ※軽自動車は、旧様式と新様式がありますので、ご注意ください。
      </div>
    </v-row>
    <v-row class="px-2">
      <v-col cols="12" sm="6" md="4" lg="3">
        <p>
          <strong>軽自動車</strong>
          <span class="text-caption">（旧様式*～平成16年10月13日）</span>
        </p>
        <Modal :modalItems="modalItems[0]"></Modal>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <p>
          <strong>軽自動車</strong>
          <span class="text-caption">（新様式*平成16年10月14日～）</span>
        </p>
        <Modal :modalItems="modalItems[1]"></Modal>
      </v-col>
    </v-row>
    <v-row class="px-2">
      <v-col cols="12" sm="6" md="4" lg="3">
        <p>
          <strong>普通乗用車</strong>
        </p>
        <Modal :modalItems="modalItems[2]"></Modal>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <p>
          <strong>小型二輪</strong>
        </p>
        <Modal :modalItems="modalItems[3]"></Modal>
      </v-col>
    </v-row>
    <v-row class="px-2">
      <v-col cols="12" sm="6" md="4" lg="3">
        <p>
          <strong>軽二輪</strong>
        </p>
        <Modal :modalItems="modalItems[4]"></Modal>
      </v-col>
    </v-row>
    <v-row class="px-2">
      <ol>
        <li>1.自動車検査証はあくまでイメージです。</li>
        <!-- eslint-disable-next-line prettier/prettier -->
        <li>2.軽二輪・届出済証は、都道府県により形式が多少異なる場合があります。
        </li>
      </ol>
    </v-row>
  </v-container>
</template>

<script>
import Modal from "../components/Modal";

export default {
  name: "VehicleInspectionText",
  components: {
    Modal,
  },
  data: function () {
    return {
      //車検証画像
      modalItems: [
        {
          modalImgSrc: require("@/assets/img/sample01.gif"),
          modalContentsImgSrc: require("@/assets/img/sample_01_big.gif"),
        },
        {
          modalImgSrc: require("@/assets/img/sample02.gif"),
          modalContentsImgSrc: require("@/assets/img/sample_02_big.gif"),
        },
        {
          modalImgSrc: require("@/assets/img/sample03.gif"),
          modalContentsImgSrc: require("@/assets/img/sample_03_big.gif"),
        },
        {
          modalImgSrc: require("@/assets/img/sample04.gif"),
          modalContentsImgSrc: require("@/assets/img/sample_04_big.gif"),
        },
        {
          modalImgSrc: require("@/assets/img/sample05.gif"),
          modalContentsImgSrc: require("@/assets/img/sample_05_big.gif"),
        },
      ],
    };
  },
};
</script>

<style scoped>
ol {
  list-style-type: none;
}

li::before {
  content: "注";
}
</style>
