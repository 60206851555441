import "babel-polyfill";
import "fetch-polyfill";
import "es6-promise";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Mock from "@/mock";
import router from "./router";

Vue.config.productionTip = false;

if (process.env.NODE_ENV.trim() === "mock") {
  Mock.bootstrap();
}

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
