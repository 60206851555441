<template>
  <v-container>
    <v-card elevation="2" color="#dddddd" class="px-2">
      <v-row class="px-2" align="end">
        <v-col cols="8" sm="5" md="4">
          <v-form @submit.prevent ref="form" v-model="isFormValid">
            <v-text-field
              ref="form"
              v-model="vin"
              :rules="vinRules"
              label="車台番号を入力してください"
              hide-details="auto"
              @keydown.enter="post"
              background-color="#ffffff"
            >
            </v-text-field>
          </v-form>
        </v-col>
        <v-col>
          <v-btn
            :disabled="!isFormValid"
            elevation="2"
            v-on:click.prevent="post"
            >検索</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="px-2">
        <v-col>
          例：HA23S-600001
          <strong
            style="text-align: left; white-space: nowrap"
            class="red--text text--lighten-1"
          >
            （半角英数字）
          </strong>
        </v-col>
      </v-row>
    </v-card>
    <div v-show="isDisplayMessage">
      <v-row class="px-2 mt-8">
        <strong class="red--text text--lighten-3">
          <div class="css-test" v-html="resultMessage"></div>
        </strong>
      </v-row>
      <v-row class="px-2 mt-8">
        <strong>
          <div class="css-test">
            【 入力された車台番号&nbsp;<span v-html="searchedVin"></span> 】
          </div>
        </strong>
      </v-row>
    </div>
    <div v-show="isDisplayTable">
      <v-row class="px-2 mt-8">
        <v-data-table
          :headers="headers"
          :items="details"
          :items-per-page="-1"
          hide-default-footer
          disable-sort
        >
          <template v-slot:[`item.title`]="{ item }">
            <a
              :href="item.detailsPageUrl"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ item.title }}
            </a>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div v-html="item.status"></div>
          </template>
        </v-data-table>
      </v-row>
    </div>
    <div v-show="isDisplayResultDescription">
      <ResultDescriptionText></ResultDescriptionText>
    </div>
    <div v-show="!isDisplayResultDescription">
      <CautionText></CautionText>
    </div>
  </v-container>
</template>

<script>
import ResultDescriptionText from "../views/ResultDescriptionText";
import CautionText from "../views/CautionText";
import axios from "axios";

export default {
  name: "RecallSearch",
  components: {
    ResultDescriptionText,
    CautionText,
  },
  data: function () {
    return {
      //リコール情報を検索するAPI
      url: "/api/recallinfo",
      //車体番号入力値
      vin: this.$route.params["vin"] ?? "",
      //入力規則
      vinRules: [
        //必須チェック
        (value) => !!value || "必ず入力してください",
        //桁数チェック
        (value) => value.length <= 17 || "17文字以内で入力してください",
        //文字種チェック
        (value) => {
          const pattern = /^[0-9a-zA-Z-]{1,17}$/;
          return (
            pattern.test(value) || "半角英数字とハイフンで入力してください。"
          );
        },
      ],
      //テーブルのフィールド
      headers: [
        { text: "", value: "no" },
        { text: "届出日（通知日）", value: "reportingDay" },
        { text: "内容", value: "title" },
        { text: "区分", value: "recallClass" },
        { text: "実施状況", value: "status" },
      ],
      //テーブルへのバインド
      details: [],
      //結果メッセージ内容
      resultMessage: "",
      searchedVin: "",
      //各種表示制御フラグ
      isDisplayMessage: false,
      isDisplayTable: false,
      isDisplayResultDescription: false,
      isFormValid: false,
    };
  },
  mounted: function () {
    if (this.vin) {
      this.post();
    }
  },
  methods: {
    //v-on:click="post"時の処理
    post: function () {
      if (window.fetch === undefined) {
        window.fetch = fetch;
      }

      var convertedVin = this.vin;

      //バリデーション
      if (!this.$refs.form.validate()) {
        return;
      }

      // サニタイジング処理
      convertedVin = this.replaceRegExp(convertedVin);

      //Axiosを使ったAJAX
      //リクエスト時のオプションの定義
      var config = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application / x-www-form-urlencoded",
        },
        withCredentials: true,
      };

      this.searchedVin = convertedVin.toUpperCase();

      //vueでバインドされた値はmethodの中ではthisで取得できる
      var param = {
        OperationType: "QUERY",
        vin: this.searchedVin,
      };

      this.details = [];
      var self = this;

      //Ajaxリクエスト(axiosを利用してリクエスト)
      axios
        .post(this.url, param, config)
        .then(function (res) {
          if (
            res.data.length > 0 &&
            res.data[0].resultMessage.trim().length !== 0
          ) {
            self.resultMessage = res.data[0].resultMessage;
            self.isDisplayResultDescription = false;
            self.hideTable();
            self.isDisplayMessage = true;
            return;
          }

          //vueにバインドされている値を書き換えると表示に反映される
          res.data.forEach(function (element, index) {
            self.details.push({
              no: index + 1,
              status:
                element.status === "0"
                  ? "<strong class='red--text text--lighten-1'>未実施</strong>"
                  : "実施済",
              recallClass: element.recallClass,
              title: element.title,
              reportingDay:
                element.reportingDay.substr(0, 4) +
                "/" +
                element.reportingDay.substr(4, 2) +
                "/" +
                element.reportingDay.substr(6, 2),
              detailsPageUrl: element.detailsPageUrl,
            });
          });

          if (self.details.length > 0) {
            self.showTable();
            self.showResults();
          } else {
            // 0件だった場合の処理
            self.hideTable();
            self.showNoResults();
          }
        })
        .catch(function (error) {
          console.log(error);
          self.resultMessage =
            "サーバーとの通信に失敗しました。お時間を空けて再検索していただくか、お問い合わせ先までご連絡お願いします。";
          self.isDisplayMessage = true;
          self.isDisplayResultDescription = false;
        });
    },
    showTable: function () {
      this.isDisplayMessage = true;
      this.isDisplayTable = true;
    },
    hideTable: function () {
      this.isDisplayMessage = false;
      this.isDisplayTable = false;
    },
    showResults: function () {
      this.resultMessage =
        "お客様が入力された車台番号のお車は「リコール・改善対策・サービスキャンペーン」の対象です。";
      this.isDisplayMessage = true;
      this.isDisplayResultDescription = true;
    },
    showNoResults: function () {
      this.resultMessage =
        "お客様が入力された車台番号のお車は「リコール・改善対策・サービスキャンペーン」の対象ではありません。";
      this.isDisplayMessage = true;
      this.isDisplayResultDescription = false;
    },
    replaceRegExp: function (str) {
      if (typeof str !== "string") {
        return str;
      }
      return str.replace(/[&'`"<>]/g, function (match) {
        return {
          "&": "&amp;",
          "'": "&#x27;",
          "`": "&#x60;",
          '"': "&quot;",
          "<": "&lt;",
          ">": "&gt;",
        }[match];
      });
    },
  },
};
</script>

<style>
.v-data-table th {
  white-space: nowrap;
}
.css-test {
  white-space: pre-wrap;
}
</style>
